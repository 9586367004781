export default function useUtmParams() {
  const route = useRoute();
  const queryParams = route.query;

  const utm_source = queryParams.utm_source || 'newtify';
  const utm_medium = queryParams.utm_medium || 'direct';
  const utm_campaign = queryParams.utm_campaign || 'direct';

  return { utm_source, utm_medium, utm_campaign };
}
