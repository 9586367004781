<script setup lang="ts">
import type { NuxtError } from '#app';
import { useClipboard } from '~/utilities/helpers/clipboard';

const props = defineProps({
  error: Object as () => NuxtError,
});

const { copy, isCopied } = useClipboard();

const handleCopy = () => {
  copy('hi@newtify.io');
};

const errors: Record<number, { title: string; text1: string; text2: string }> =
  {
    400: {
      title: 'Error on the page',
      text1: 'Try refreshing the page later',
      text2: 'If the error persists, please contact technical support',
    },
    401: {
      title: 'Error on the page',
      text1: 'Try refreshing the page later',
      text2: 'If the error persists, please contact technical support',
    },
    403: {
      title: 'Access error',
      text1: 'Your user does not have permission to view this page',
      text2:
        'Please contact the administrator of Autoimport Group for access rights or try again',
    },
    404: {
      title: 'Page not found',
      text1: '',
      text2: '',
    },
    500: {
      title: 'Service unavailable',
      text1:
        "We are performing maintenance to improve the service's speed and quality. We apologize for the inconvenience.",
      text2: '',
    },
  };

const errorDetails = computed(() => {
  const status = props.error?.statusCode ?? 0;
  return (
    errors[status] || {
      title: 'Unknown error',
      text1: 'An unexpected error occurred.',
      text2: '',
    }
  );
});

const { utm_source, utm_medium, utm_campaign } = useUtmParams();

const googlePlayUrl = computed(() => {
  return `http://play.google.com/store/apps/details?id=io.newtify.app&referrer=utm_source%3D${utm_source}%26utm_medium%3D${utm_medium}%26utm_campaign%3D${utm_campaign}`;
});
</script>

<template>
  <div class="error-page-wrapper">
    <div class="error-page">
      <div class="error-page-top">
        <base-icon
          name="base/logo_error"
          size="160px"
          filled
          class="error-icon"
        ></base-icon>
        <h1 class="error-title font--h1">
          {{ errorDetails.title }}
        </h1>
        <p v-if="errorDetails.text1" class="error-text font--b1">
          {{ errorDetails.text1 }}
        </p>
        <p v-if="errorDetails.text2" class="error-text font--b1">
          {{ errorDetails.text2 }}
        </p>
        <nuxt-link class="error-link font--b2 hover-scale-light" to="/"
          >Back home</nuxt-link
        >

        <div class="email-link-wrapper">
          <span class="font--b2">Or write us to support</span>
          <nuxt-link
            to="mailto::hi@newtify.io"
            class="email-link hover-scale-light font--b2"
          >
            hi@newtify.io
          </nuxt-link>
          <transition name="fade" mode="out-in">
            <button
              v-if="!isCopied"
              @click="handleCopy"
              class="copy-email hover-scale-light"
            >
              <base-icon name="base/copy" size="24px" filled />
            </button>
            <base-icon v-else name="base/valid" size="24px" filled></base-icon>
          </transition>
        </div>
      </div>

      <div class="error-page-bottom">
        <nuxt-link
          v-if="$device.isIos || $device.isDesktop"
          to="https://apps.apple.com/us/app/newtify-io/id6482849312"
          class="download-button hover-scale-light"
          target="_blank"
        >
          <Icon size="48" name="icons:app-store-button" class="" />
        </nuxt-link>
        <nuxt-link
          v-if="!$device.isIos || $device.isDesktop"
          :to="googlePlayUrl"
          class="download-button hover-scale-light"
          target="_blank"
        >
          <Icon size="48" name="icons:play-store-button" class="" />
        </nuxt-link>
        <nuxt-img
          style="display: none;"
          v-if="$device.isDesktop"
          src="/v2/qr-template.png"
          width="92"
          height="92"
          class="qr-code-image"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.error-page-wrapper {
  width: 100%;
}

.error-page {
  width: 100%;
  height: 100vh;

  max-width: 1440px;
  padding: 160px 0 64px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.error-page-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-bottom: 32px;
}

.error-title {
  margin-bottom: 16px;
  text-align: center;
}

.error-text {
  margin-bottom: 16px;
  text-align: center;
}

.error-link {
  background-color: var(--primary-orange);
  color: var(--white);
  cursor: pointer;
  width: fit-content;
  border: none;
  outline: none;
  padding: 8px 24px;
  height: 44px;
  text-align: center;
  border-radius: 44px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.email-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.email-link {
  color: var(--primary-orange);
  text-underline-offset: 5px;
}

.copy-email {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.error-page-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code-image {
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .error-page {
    padding: 25vh 16px 5vh;
  }

  .error-icon {
    margin-bottom: 24px;
  }

  :deep(.base-icon).error-icon {
    width: 80px;
    height: 80px;
  }

  :deep(.base-icon).error-icon .nuxt-icon {
    width: 80px;
    height: 80px;
  }

  :deep(.base-icon).error-icon .nuxt-icon svg {
    width: 80px;
    height: 80px;
  }
}
</style>
