
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexrDqMSVkmqJ_45K4ru_jaNFtZOaP2MAuxd4p4vlycfVaVcMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexqhS9znM9p_DEjxXV9Pbdsfz2zfrbVDERf8_TLa_vhvsMeta } from "/app/pages/exchange/index.vue?macro=true";
import { default as indexnmtc_CRIGMl8bQY8kcWfHZPoBfSC_45Fn0SKIBIlRgAJgMeta } from "/app/pages/faq/index.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as indexO5uEZ7uDVEZ82mnl_457NuqJlqVKr9ahfUu1syvkf8GM0Meta } from "/app/pages/leaderboard/index.vue?macro=true";
import { default as index6E98x3ANoio3g_RWOwpdJeSOVKaDpTUBiNMspktOUTAMeta } from "/app/pages/policy/index.vue?macro=true";
export default [
  {
    name: "events",
    path: "/events",
    meta: indexrDqMSVkmqJ_45K4ru_jaNFtZOaP2MAuxd4p4vlycfVaVcMeta || {},
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "exchange",
    path: "/exchange",
    meta: indexqhS9znM9p_DEjxXV9Pbdsfz2zfrbVDERf8_TLa_vhvsMeta || {},
    component: () => import("/app/pages/exchange/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: indexnmtc_CRIGMl8bQY8kcWfHZPoBfSC_45Fn0SKIBIlRgAJgMeta || {},
    component: () => import("/app/pages/faq/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "leaderboard",
    path: "/leaderboard",
    meta: indexO5uEZ7uDVEZ82mnl_457NuqJlqVKr9ahfUu1syvkf8GM0Meta || {},
    component: () => import("/app/pages/leaderboard/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: index6E98x3ANoio3g_RWOwpdJeSOVKaDpTUBiNMspktOUTAMeta || {},
    component: () => import("/app/pages/policy/index.vue")
  }
]