import payload_plugin__iIxKyK_yDkYvHiFQCwaNmrXxbo_XpUBJqr_blR2DrM from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_sKRW6AS4KlZ7a56y3Ec1bfOBJI0oOwOj2kObWwXNhnI from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead__gULi3OUh2KlBxEtvuNlaFQWyGVNH5s0qpheLSMdJNU from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6_CQ6B07iz3zEBRoLGuCDgUvLzrsUXYUmBMIc81UyHc from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_zBhYypBC4gOs1v1XfMYGFFDhQoDVAEMvpFcnLqNrpxk from "/app/node_modules/.pnpm/nuxt-site-config@3.1.5_magicast@0.3.5_vue@3.5.13_typescript@5.6.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_QnZDzD2SHG_GfZWDkgKhvaNnbDoo4UHd6FzJHxa734M from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0eRLegFeR7U8z0_r1Vke_0hGejLHGKt4IE832oVjmSU from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Dz2QWXO6lkR3eq1scYYjuADaRmgD4FxRbPxZVGTp4TI from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_moFHuphuDOFI6wmNWIq_8h_P_syInSEz27lBrWqkuEU from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_vyXJKBuW2ceAipcUJAFmmMt0XAGqHNlQTkcMRWh_ucs from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_7pCVPyXnmIaGpA2IgjW_sFTL5Jp5gcPkUHs_Yj0rtC8 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_db0@0.3.1_eslint@_8a463816340e365b7b3a0087747a267c/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_7ZQwtSiu42tXWQNC3IGrmOgDhm4ZHG4_X2O8vGeVo5Y from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.9.0_jiti@2.4.2_sass@1.85.1_t_f6b728b4486dd17c191af8377b043b48/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_Y80bV5FY6p93_MZhHnbRMYnhSf0RbYP3RUteOTcwA_Q from "/app/node_modules/.pnpm/@sentry+nuxt@9.5.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@_6924f18ccd1783bb8656a2dd5c44abb8/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/app/.nuxt/sentry-client-config.mjs";
import gtm_pDGwsGsZLC_j8OVvW0RWZTbOqG__WOThnuZd9O_HMHM from "/app/plugins/gtm.js";
import recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg from "/app/plugins/recaptcha.ts";
import Vue3Lottie_client_z7wCDCIvaoJIre21XGv8RZLne21Yq9w4QSIt8zAbUPg from "/app/plugins/Vue3Lottie.client.ts";
export default [
  payload_plugin__iIxKyK_yDkYvHiFQCwaNmrXxbo_XpUBJqr_blR2DrM,
  revive_payload_client_sKRW6AS4KlZ7a56y3Ec1bfOBJI0oOwOj2kObWwXNhnI,
  unhead__gULi3OUh2KlBxEtvuNlaFQWyGVNH5s0qpheLSMdJNU,
  router_6_CQ6B07iz3zEBRoLGuCDgUvLzrsUXYUmBMIc81UyHc,
  _0_siteConfig_zBhYypBC4gOs1v1XfMYGFFDhQoDVAEMvpFcnLqNrpxk,
  payload_client_QnZDzD2SHG_GfZWDkgKhvaNnbDoo4UHd6FzJHxa734M,
  navigation_repaint_client_0eRLegFeR7U8z0_r1Vke_0hGejLHGKt4IE832oVjmSU,
  check_outdated_build_client_Dz2QWXO6lkR3eq1scYYjuADaRmgD4FxRbPxZVGTp4TI,
  chunk_reload_client_moFHuphuDOFI6wmNWIq_8h_P_syInSEz27lBrWqkuEU,
  plugin_vue3_vyXJKBuW2ceAipcUJAFmmMt0XAGqHNlQTkcMRWh_ucs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_7pCVPyXnmIaGpA2IgjW_sFTL5Jp5gcPkUHs_Yj0rtC8,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  plugin_7ZQwtSiu42tXWQNC3IGrmOgDhm4ZHG4_X2O8vGeVo5Y,
  sentry_client_Y80bV5FY6p93_MZhHnbRMYnhSf0RbYP3RUteOTcwA_Q,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  gtm_pDGwsGsZLC_j8OVvW0RWZTbOqG__WOThnuZd9O_HMHM,
  recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg,
  Vue3Lottie_client_z7wCDCIvaoJIre21XGv8RZLne21Yq9w4QSIt8zAbUPg
]