import { ref } from 'vue';

export const useClipboard = () => {
  const isCopied = ref(false);

  const copy = async (value: string) => {
    await navigator.clipboard.writeText(value);
    isCopied.value = true;
    setTimeout(() => {
      isCopied.value = false;
    }, 2000); // Reset after 2 seconds
  };

  return { copy, isCopied };
};